<template lang="pug">
	transition(name="slide-fade")
		div
			loading(:active.sync="busy", :is-full-page="true")
			form(@submit.prevent="submit", v-if="mmpAccount")

				.buttons.text-right
					router-link.btn.btn-default(:to="{name:'mmp-account-list'}")
						i.la.la-angle-left
						| Back
					| &nbsp;
					button.btn.btn-primary(type="submit") Save
						i.la.la-fw.la-save

				div
					b-tabs(content-class="mt-3")
						b-tab(title="Main")

							.row
								.col-sm-12
									.widget
										.widget-header
											h1.title {{ mmpAccount.id ? 'Edit MMP Account' : 'Create MMP Account' }}
										.widget-body
											.row
												.col-sm-12(v-if="mmpAccount.id")
													.form-group
														label ID
														input.form-control(type="text", disabled="disabled", :value="mmpAccount.id")

											.row
												.col-sm-12
													.form-group
														label Type
														select.form-control(v-model="mmpAccount.type", :disabled="!!mmpAccount.id")
															option(v-for="o in typeOptions", :value="o.v") {{ o.t }}

											.row
												.col-sm-12
													.form-group
														label Account Name
															i.la.la-info-circle.ml-2(v-b-tooltip.hover, title="Use the same name as in the MMP platform")
														input.form-control(type="text", v-model="mmpAccount.account_name", :disabled="!!mmpAccount.id")
											.row(v-if="mmpAccount.type === 'appsflyer'")
												.col-sm-12
													.form-group
														label AF Encryption Key
															i.la.la-info-circle.ml-2(v-b-tooltip.hover, title="For sending encrypted cost data on AF offers. Request your network encryption key from your Partner Development Manager or reach out to partners@appsflyer.com.")
														input.form-control(type="text", v-model="mmpAccount.encryption_key")
											.row(v-if="mmpAccount.type === 'appsflyer'")
												.col-sm-12
													.form-group
														label AF Secret Token
															i.la.la-info-circle.ml-2(v-b-tooltip.hover, title="API Secret Token. Can be obtained from account's security center.")
														input.form-control.mb-2(type="text", v-model="mmpAccount.secret_token")
														.d-flex.gap-2.align-items-center
															button.btn.btn-primary(type="button", @click="verify") Verify
															span(v-for="v of afAllowedReportsList") {{ getAfReportTypeName(v.name) }} {{ v.isAllowed ? '✅' : '❌' }}
											.row(v-if="mmpAccount.type === 'appsflyer'")
												.col-sm-12
													.form-group
														label AF Clicks Signing Mode
															i.la.la-info-circle.ml-2(v-b-tooltip.hover, title="Clicks signing mode.")
														select.form-control(v-model="mmpAccount.af_clicks_signing_mode")
															option(v-for="o in afClicksSigningModeOptions" :value="o.v") {{ o.t }}
											.row(v-if="mmpAccount.type === 'appsflyer'")
												.col-sm-12
													.form-group
														label Offer Subsource Auto-Override Rejections Count
															i.la.la-info-circle.ml-2(v-b-tooltip.hover, title="How many rejections should occur before subsources will be overridden.")
														input.form-control(type="text", v-model="mmpAccount.subsource_override_rejections_count")

									.widget
										.widget-header
											h2.title Panel Information
										.widget-body
											.row
												.col-sm-6
													.form-group
														label Username
														input.form-control(type="text", v-model="mmpAccount.panel_username")
												.col-sm-6
													.form-group
														label Password
														b-input-group
															b-form-input(v-model="mmpAccount.panel_password")
															b-input-group-append
																b-button(type="button", v-clipboard:copy="mmpAccount.panel_password", v-clipboard:success="onCopySuccess", v-clipboard:error="onCopyError",
																	v-b-tooltip.hover.bottom, title="Copy to clipboard")
																	i.la.la-copy




						b-tab(title="Postbacks", v-if="mmpAccount.id && mmpAccount.type")
							.widget
								.widget-header
									h2.title Attribution Link
								.widget-body
									.row(v-if="mmpAccount.type === 'appsflyer'")
											.col-sm-12
												h4.widget-subtitle Macros
													i.ml-2.la.la-info-circle(v-b-tooltip.hover.right, title="Make sure all of the macros are active and set as follows")
												table.macros-list.table
													thead
														tr
															th Parameter name
															th Your receiving parameter
													tbody
														tr(v-for="m in attributionLinkMacros")
															td {{ m.af }}
															td {{ m.value }}

							.widget
								.widget-header
									h2.title Postbacks
								.widget-body
									h4.widget-subtitle Endpoint Addresses
										i.ml-2.la.la-info-circle(v-b-tooltip.hover.right, title="Use these URLs to associate the postbacks to this MMP account")
									.row
										.col-sm-12
											.form-group
												label Install Postback URL
												b-input-group
													b-form-input(:value="installURL", readonly="readonly")
													b-input-group-append
														b-button(type="button", v-clipboard:copy="installURL", v-clipboard:success="onCopySuccess", v-clipboard:error="onCopyError",
															v-b-tooltip.hover.bottom, title="Copy to clipboard")
															i.la.la-copy
									.row
										.col-sm-12
											.form-group
												label In-App Event Postback URL
												b-input-group
													b-form-input(:value="eventURL", readonly="readonly")
													b-input-group-append
														b-button(type="button", v-clipboard:copy="eventURL", v-clipboard:success="onCopySuccess", v-clipboard:error="onCopyError",
															v-b-tooltip.hover.bottom, title="Copy to clipboard")
															i.la.la-copy

									.row(v-if="mmpAccount.type !== 'appsflyer'")
										.col-sm-12
											.form-group
												label Rejection Postback URL
												b-input-group
													b-form-input(:value="rejectURL", readonly="readonly")
													b-input-group-append
														b-button(type="button", v-clipboard:copy="rejectURL", v-clipboard:success="onCopySuccess", v-clipboard:error="onCopyError",
															v-b-tooltip.hover.bottom, title="Copy to clipboard")
															i.la.la-copy

									.row(v-if="mmpAccount.type === 'appsflyer'")
										.col-sm-12
											h4.widget-subtitle Postback Macros
												i.ml-2.la.la-info-circle(v-b-tooltip.hover.right, title="Make sure all of the macros are active and set as follows")
											table.macros-list.table
												thead
													tr
														th AppsFlyer parameter
														th Your receiving parameter
														th Value
												tbody
													tr(v-for="m in defaultPbMacros")
														td {{ m.af }}
														td {{ m.value }}
														td {{ m.type }}

							.widget
								.widget-header
									h2.title Advanced Privacy Postbacks
								.widget-body
									h4.widget-subtitle Endpoint Addresses
										i.ml-2.la.la-info-circle(v-b-tooltip.hover.right, title="Use these URLs to associate the postbacks to this MMP account")
									.row
										.col-sm-12
											.form-group
												label Install Postback URL
												b-input-group
													b-form-input(:value="installAPURL", readonly="readonly")
													b-input-group-append
														b-button(type="button", v-clipboard:copy="installAPURL", v-clipboard:success="onCopySuccess", v-clipboard:error="onCopyError",
															v-b-tooltip.hover.bottom, title="Copy to clipboard")
															i.la.la-copy
									.row
										.col-sm-12
											.form-group
												label In-App Event Postback URL
												b-input-group
													b-form-input(:value="eventAPURL", readonly="readonly")
													b-input-group-append
														b-button(type="button", v-clipboard:copy="eventAPURL", v-clipboard:success="onCopySuccess", v-clipboard:error="onCopyError",
															v-b-tooltip.hover.bottom, title="Copy to clipboard")
															i.la.la-copy

									.row(v-if="mmpAccount.type !== 'appsflyer'")
										.col-sm-12
											.form-group
												label Rejection Postback URL
												b-input-group
													b-form-input(:value="rejectAPURL", readonly="readonly")
													b-input-group-append
														b-button(type="button", v-clipboard:copy="rejectAPURL", v-clipboard:success="onCopySuccess", v-clipboard:error="onCopyError",
															v-b-tooltip.hover.bottom, title="Copy to clipboard")
															i.la.la-copy

									.row(v-if="mmpAccount.type === 'appsflyer'")
										.col-sm-12
											h4.widget-subtitle Postback Macros
												i.ml-2.la.la-info-circle(v-b-tooltip.hover.right, title="Make sure all of the macros are active and set as follows")
											table.macros-list.table
												thead
													tr
														th AppsFlyer parameter
														th Your receiving parameter
														th Value
												tbody
													tr(v-for="m in apMacros")
														td {{ m.af }}
														td {{ m.value }}
														td {{ m.type }}


						b-tab(title="Reports", v-if="mmpAccount.id && mmpAccount.type === 'appsflyer'")
							.widget
								.widget-header
									h1.title Data Locker
								.widget-body
									h4.widget-subtitle Set these configurations after bucket is connected
									.row
										.col-sm-12
											.form-group
												b-form-checkbox(v-model="mmpAccount.datalocker_enabled") Enabled
													i.la.la-info-circle.ml-2(v-b-tooltip.hover.right, title="Optimize subsources by Data Locker's report")

									.row
										.col-sm-12
											.form-group
												label Bucket Name
												input.form-control(type="text", v-model="mmpAccount.datalocker_bucket")

									.row
										.col-sm-12
											.form-group
												label Connection Name
												input.form-control(type="text", v-model="mmpAccount.datalocker_connection")

									.row
										.col-sm-12
											.form-group
												label Home Folder
												input.form-control(type="text", disabled="disabled", :value="'datalocker-'+mmpAccount.account_name")

									.row
										.col-sm-12
											.form-group
												label Last Report Fetched
												input.form-control(type="text", disabled="disabled", :value="mmpAccount.datalocker_last_report")
	
							.widget
								.widget-header
									h1.title Post-Attribution
								.widget-body
									.row(v-if="mmpAccount.type === 'appsflyer'")
										.col-sm-12
											.form-group
												b-checkbox(v-model="mmpAccount.should_fetch_reports") Fetch Reports Automatically
													i.la.la-info-circle.ml-2(v-b-tooltip.hover, title="If Post-Attribution reports should be fetch automatically. AppsFlyer Secret Token required.")


</template>
<script>
import Vue from 'vue';

const afReportType = {
	PostAttributionInAppEventsFraud: 'post_attribution_inapp_events_fraud',
	PostAttributionInstalls: 'post_attribution_installs',
	/** Those report types used only for AF reports sender */
	InAppEventsPostbacks: 'inapp_events_postbacks',
	InstallsPostbacks: 'installs_postbacks',
}
const afReportTypeToNameMap = {
	[afReportType.PostAttributionInAppEventsFraud]: 'Post-Attribution In-App Events Fraud',
	[afReportType.PostAttributionInstalls]: 'Post-Attribution Installs',
	/** Those report types used only for AF reports sender */
	[afReportType.InAppEventsPostbacks]: 'In-App Events Postbacks',
	[afReportType.InstallsPostbacks]: 'Installs Postbacks',
}

export default {
	name: 'MmpAccountForm',
	async beforeRouteEnter(to, from, next) {
		let mmpAccount = {};
		let config;
		let macros = [];
		let defaultPbMacros = [];
		let attributionLinkMacros = [];

		try {
			let systemMacros = await Vue.ovData.general.getUrlMacros();
			macros = systemMacros.MMP_AP_POSTBACK_MACROS;
			defaultPbMacros = systemMacros.MMP_DEFAULT_POSTBACK_MACROS;
			attributionLinkMacros = systemMacros.ATTRIBUTION_LINK_MACROS;
		} catch (e) {
			console.error(e);
		}

		if (to.name === 'mmp-account-edit') {
			let entityId = +to.params.id;
			try {
				mmpAccount = await Vue.ovData.mmpAccount.get(entityId, true);
				config = await Vue.ovReq.get('settings/getConfig');
			} catch (e) {
				return next(false);
			}
		} else {
			mmpAccount = Vue.ovData.mmpAccount.newInstance();
		}

		next((vm) => {
			vm.mmpAccount = mmpAccount;
			vm.pbBaseURL = config && config.paths ? config.paths.postback : '';
			vm.apMacros = macros;
			vm.defaultPbMacros = defaultPbMacros;
			vm.attributionLinkMacros = attributionLinkMacros;
			return vm;
		});
	},

	computed: {
		USER() {
			return this.$store.state.user;
		},
		installURL() {
			return `${this.pbBaseURL}c/all/${this.mmpAccount.id}`;
		},
		eventURL() {
			return `${this.pbBaseURL}e/all/${this.mmpAccount.id}`;
		},
		rejectURL() {
			return `${this.pbBaseURL}er/all/${this.mmpAccount.id}`;
		},
		installAPURL() {
			return `${this.pbBaseURL}ap_c/${this.mmpAccount.type}/${this.mmpAccount.id}`;
		},
		eventAPURL() {
			return `${this.pbBaseURL}ap_e/${this.mmpAccount.type}/${this.mmpAccount.id}`;
		},
		rejectAPURL() {
			return `${this.pbBaseURL}ap_r/${this.mmpAccount.type}/${this.mmpAccount.id}`;
		},
		afAllowedReportsList() {
			return Object.entries(this.afAllowedReports).map(([name, isAllowed]) => ({ name, isAllowed }));
		}
	},
	data() {
		return {
			busy: false,
			mmpAccount: null,
			apMacros: [],
			defaultPbMacros: [],
			attributionLinkMacros: [],
			typeOptions: [
				{ v: 'appsflyer', t: 'Appsflyer', pbPrefix: 'af' },
				{ v: 'kochava', t: 'Kochava', pbPrefix: 'kc' },
				{ v: 'adjust', t: 'Adjust', pbPrefix: 'aj' },
				{ v: 'singular', t: 'Singular', pbPrefix: 'sn' },
				{ v: 'branch', t: 'Branch', pbPrefix: '' },
				{ v: 'tenjin', t: 'Tenjin', pbPrefix: '' },
			],
			pbBaseURL: '',
			afClicksSigningModeOptions: [
				{ v: 'enabled', t: 'Enabled' },
				{ v: 'disabled', t: 'Disabled' },
				{ v: 'report-only', t: 'Report Only Mode (Test Mode, AF will check the signature but won\'t block clicks)' },
			],
			/** @type {Record<string, boolean>} */
			afAllowedReports: {},
		};
	},

	methods: {
		async submit() {
			if (this.busy) {
				return;
			}
			this.busy = true;
			let data = Vue.util.extend({}, this.mmpAccount);

			try {
				await this.$ovReq.post('mmpAccount/save', data);
				this.busy = false;
				this.$ovNotify.success('MMP Account has been saved');
				this.$router.push({ name: 'mmp-account-list' });
			} catch (e) {
				this.$ovNotify.error(e);
				this.busy = false;
			}
		},

		async verify() {
			const data = {
				secretToken: this.mmpAccount.secret_token,
			};

			this.afAllowedReports = await this.$ovReq.post('mmpAccount/verify', data);
		},

		onCopySuccess() {
			this.$ovNotify.success('Copied to clipboard');
		},

		onCopyError() {
			this.$ovNotify.error('Could not copy text');
		},

		/** @param {string} reportType */
		getAfReportTypeName(reportType) {
			return afReportTypeToNameMap[reportType];
		},
	},
};
</script>
